import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { EditingContext } from '../../BuilderProvider';
import CanvasElements from '../CanvasElements';

const useStyles = makeStyles((theme) => {
  return {
    canvas: {
      marginTop: 30,
      height: 'calc( 100% - 30px)',
      width: 750 / 2,
      borderRadius: 4,
      '&::-webkit-scrollbar': {
        width: '10px',
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        borderColor: theme.palette.primary.main,
      },
      '&::-webkit-scrollbar-thumb': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  };
});

const Canvas = React.memo((props) => {
  const classes = useStyles();
  const { background, color, fontFamily, menuBackground, menuColor } = props;
  return (
    <div className={classes.canvas} style={{ background, color, fontFamily }}>
      <DndProvider backend={HTML5Backend}>
        <CanvasElements
          navigation={{ menuBackground, menuColor, background, color }}
        />
      </DndProvider>
    </div>
  );
});

export default () => {
  const {
    settings: { background, fontColor, fontFamily, menuBackground, menuColor },
  } = useContext(EditingContext);
  const props = {
    background,
    color: fontColor,
    fontFamily,
    menuBackground,
    menuColor,
  };
  return <Canvas {...props} />;
};
