import React from 'react';
import { reduxForm } from 'redux-form';

import AuthForm from '../../components/AuthForm';

function SignUpForm(props) {
  const fields = [
    {
      name: 'emailAddress',
      label: 'Email',
      fullWidth: true,
      color: 'secondary',
    },
    {
      name: 'emailAddressConfirmation',
      label: 'Confirm your email ',
      fullWidth: true,
      color: 'secondary',
    },
    {
      name: 'password',
      label: 'Password',
      fullWidth: true,
      color: 'secondary',
      type: 'password',
      required: true,
    },
    {
      name: 'passwordConfirmation',
      label: 'Confirm Password',
      fullWidth: true,
      color: 'secondary',
      type: 'password',
      required: true,
    },
    {
      name: 'betaCode',
      label: 'Invitation code',
      fullWidth: true,
      color: 'secondary',
      type: 'text',
      required: true,
    },
  ];
  return (
    <AuthForm
      title="Sign up form"
      buttonTxt="Register"
      fields={fields}
      {...props}
    />
  );
}

export default reduxForm({
  form: 'SignUpForm', // a unique identifier for this form
})(SignUpForm);
