import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Typography, Grid } from '@material-ui/core';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import { saveCompany, uploadCompanyLogo } from '../../db';
import MainLayout from '../../layouts/MainLayout';
import Loader from '../../components/Loader';
import LogoUpload from './LogoUpload';
import CompanyForm from './CompanyForm';

function SettingsPage(props) {
  const companies = useSelector(
    ({ firestore: { ordered } }) => ordered && ordered.companies
  );
  const company = (companies && companies[0]) || {};
  const [logoUrl, setLogoUrl] = useState('');
  const [loading, setLoading] = useState(false);

  // Loading State
  if (!isLoaded(companies) || loading) {
    return (
      <MainLayout>
        <Loader show="true" color="secondary" />
      </MainLayout>
    );
  }

  async function handleLogoUpload({ value }) {
    const url = await uploadCompanyLogo(props.uid, value);
    setLogoUrl(url);
  }

  function handleSubmit(values) {
    setLoading(true);
    return new Promise(async (resolve) => {
      const {
        companyName: name,
        companyPhone: phone,
        companyAddress: address,
      } = values;
      await saveCompany(company.id, {
        name,
        phone,
        address,
        logo: logoUrl || company.logo,
        owner: props.uid,
      });
      setLoading(false);
      resolve();
    });
  }

  // Render created menus
  return (
    <MainLayout>
      <Container>
        <Typography
          style={{ fontWeight: 800, marginBottom: 20 }}
          color="textPrimary"
          variant="h3"
        >
          Settings
        </Typography>
        <Typography color="textPrimary" variant="h5">
          Business Information
        </Typography>
        <Grid container>
          <Grid item md={6}>
            <LogoUpload
              value={logoUrl || company.logo}
              name="logoUpload"
              onChange={handleLogoUpload}
            />
          </Grid>
          <Grid item md={6}>
            <CompanyForm loading={loading} onSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Container>
    </MainLayout>
  );
}
export default () => {
  const { uid } = useSelector((state) => state.firebase.auth);
  return <SettingsPage uid={uid} />;
};
