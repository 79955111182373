import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import firebase from '../firebase';
import {
  firebaseConnect,
  firestoreConnect,
  withFirestore,
  isEmpty,
} from 'react-redux-firebase';
import { useCookies } from 'react-cookie';

import LaunchScreen from '../components/LaunchScreen';
import NotFoundPage from '../pages/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import routes from './routes';

function Router(props) {
  const { auth, firestore } = props;
  const [cookies, setCookie] = useCookies(['minucsLastActive']);

  if (!isLoaded(auth)) return <LaunchScreen />;

  if (!isEmpty(auth) && !cookies.minucsLastActive) {
    firestore.collection('users').doc(auth.uid).set(
      {
        lastActiveDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
    setCookie('minucsLastActive', true);
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        {routes.map((route, idx) => {
          const { private: isPrivate, ...rest } = route;
          if (isPrivate) {
            return (
              <PrivateRoute path={rest.path} key={idx}>
                <rest.component />
              </PrivateRoute>
            );
          }
          return <Route {...rest} key={idx} />;
        })}
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default compose(
  firebaseConnect(),
  connect((state) => ({
    auth: state.firebase.auth,
    userProfile: state.firestore.data.userProfile,
  })),
  firestoreConnect((props) => [
    {
      collection: 'menus',
      where: [['owner', '==', props.auth.uid || null]],
    },
    {
      collection: 'users',
      doc: props.auth.uid || null,
      storeAs: 'userProfile',
    },
    {
      collection: 'companies',
      where: [['owner', '==', props.auth.uid || null]],
    },
  ]),
  withFirestore
)(Router);
