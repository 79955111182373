import React from 'react';
import { makeStyles, Button as MaterialButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      color: '#ffffea',
      borderRadius: 40,
      textTransform: 'none',
      boxShadow: '7px 7px 0px 0px rgba(0,0,0,0.14)',
      minWidth: 180,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        width: 260,
      },
    },
  };
});

function Button(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <MaterialButton
      classes={{
        root: classes.button,
      }}
      {...rest}
    >
      {children}
    </MaterialButton>
  );
}

export default Button;
