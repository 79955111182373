import React, { Component, useState } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {
  Button,
  Dialog,
  DialogContent,
  withStyles,
  TextField,
  DialogActions,
  IconButton,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import clsx from 'clsx';

import MainLayout from '../../layouts/MainLayout';
import { getMenu, saveMenu, uploadImg } from '../../db';
import Builder, { BuilderProvider } from '../../builder';
import QRCodeDialog from '../../components/QRCodeDialog';

const styles = (theme) => ({
  sidebarActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebarAction: {
    boxShadow: 'none',
    margin: '0 10px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  downloadQRBtn: {
    backgroundColor: '#c3d1e5',
    color: theme.palette.secondary.main,
  },
  saveBtn: {
    color: '#fff',
  },
  dialog: {
    width: 300,
  },
  name: {
    padding: '0px 10px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& a': {
      marginLeft: 5,
    },
  },
});

const DEFAULT_SETTINGS = {
  background: '#fff',
  fontFamily: 'Open Sans',
  fontColor: '#031C40',
  menuBackground: '#FF7733',
  menuColor: 'rgba(0, 0, 0, 0.87)',
};

const QRCode = (props) => {
  const { uid } = props;
  const [open, setOpen] = useState(false);
  function toggle() {
    setOpen(!open);
  }
  return (
    <>
      <Button variant="contained" color="secondary" onClick={toggle}>
        QR Code
      </Button>
      <QRCodeDialog uid={uid} open={open} onClose={toggle} />
    </>
  );
};
class MenuPage extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: Boolean(props.match.params?.menuId),
      name: 'Menú de La Pastelería',
      displayQr: false,
      pristine: true,
      savedBuilder: {},
      saving: false,
      nameError: false,
      nameDialog: false,
    };
    this.builderRef = React.createRef();
  }

  componentDidMount() {
    if (this.menuId) {
      this.loadMenu();
    }
  }

  loadMenu = async () => {
    const { history } = this.props;
    try {
      const menu = await getMenu(this.menuId);
      const { name, ...rest } = menu;
      this.setState({
        loading: false,
        name,
        savedBuilder: rest,
      });
    } catch (e) {
      console.error(e);
      history.push('/404');
    }
  };

  get initialBuilderState() {
    const { company = {} } = this.props;
    const {
      savedBuilder: { settings = {}, ...rest },
    } = this.state;

    return {
      blocks: [
        {
          data: { fontSize: '20', title: 'Drinks' },
          id: 'block-0',
          blockId: 'title',
        },
        {
          id: 'block-1',
          data: {
            description: '',
            name: 'Raspberry Soda',
            price: '15',
          },
          blockId: 'dish',
        },
        {
          blockId: 'dish',
          data: { price: '15', description: '', name: 'Watermellon Soda' },
          id: 'block-2',
        },
        {
          id: 'block-3',
          data: { price: '16', name: 'Mango Juice', description: '' },
          blockId: 'dish',
        },
        {
          id: 'block-4',
          blockId: 'title',
          data: { fontSize: '20', title: 'Appetizers' },
        },
        {
          data: { description: '', price: '50', name: 'Aztec Soup' },
          blockId: 'dish',
          id: 'block-5',
        },
        {
          id: 'block-6',
          blockId: 'dish',
          data: { price: '40', description: '', name: 'Nachos' },
        },
        {
          data: { price: '30', name: 'Guacamole', description: '' },
          blockId: 'dish',
          id: 'block-7',
        },
        {
          id: 'block-9',
          data: { title: 'Main Courses', fontSize: '20' },
          blockId: 'title',
        },
        {
          blockId: 'dish',
          data: {
            name: 'Fried Chicken',
            description:
              'Garlic marinated with chile quebrado. Includes french fries.',
            price: '2000',
          },
          id: 'block-10',
        },
        {
          data: {
            price: '250',
            // description:
            //   'Gratinada con queso provolone y servida con papas a la\nfrancesa.',
            name: 'Club sandwich',
          },
          blockId: 'dish',
          id: 'block-11',
        },
        {
          data: {
            description:
              'Our finest ingredients give life to one of the greates pizzas in the world.',
            price: '250',
            name: 'Pepperoni Pizza',
          },
          id: 'block-12',
          blockId: 'dish',
        },
        {
          id: 'block-13',
          data: { title: 'Desserts', fontSize: '20' },
          blockId: 'title',
        },
        {
          id: 'block-14',
          blockId: 'dish',
          data: {
            price: '95',
            // description:
            //   'Servido con helado de vainilla. Bañado con chocolate, almendras tostadas y ralladura de chocolate amargo',
            name: 'Brownie',
          },
        },
        {
          id: 'block-15',
          blockId: 'dish',
          data: {
            description:
              'Cream and delicious vanilla icream naturally sweetened.',
            name: 'Vanilla Icream',
            price: '60',
          },
        },
      ],
      nextId: 16,
      ...rest,
      settings: {
        ...DEFAULT_SETTINGS,
        ...settings,
        company,
      },
    };
  }

  deleteMenu = async () => {
    const {
      firestore,
      history,
      match: {
        params: { menuId },
      },
    } = this.props;
    this.setState({
      loading: true,
    });
    await firestore.doc(`menus/${menuId}`).delete();
    setTimeout(() => {
      history.push('/dashboard');
    }, 500);
  };

  localStorageUrl = () => {
    return `minucs-${this.menuId || 'new'}`;
  };

  get menuId() {
    return this.props.match.params?.menuId;
  }

  get userId() {
    return this.props.auth.uid;
  }

  saveToLocalStorage = (builderData) => {
    this.setState({
      pristine: false,
    });
    this.builderData = builderData;
    localStorage.setItem(this.localStorageUrl(), JSON.stringify(builderData));
  };

  saveToFirebase = async () => {
    // TODO: Find a better to do this or wait till moving to Redux
    if (this.builderRef.current.state.editId) {
      return this.builderRef.current.saveAndQuitEditing((data) => {
        this.builderData = data;
        this.saveToFirebase();
      });
    }

    this.setState({ loading: true, saving: true });
    const { id } = await saveMenu(this.menuId, {
      owner: this.userId,
      name: this.state.name,
      ...this.builderData,
    });
    if (!this.menuId) {
      this.props.history.push(`/dashboard/menus/${id}`);
    } else {
      this.setState({ loading: false, saving: false, pristine: true });
    }
  };

  openNameDialog = () => {
    this.setState({ nameDialog: true });
  };

  closeNameDialog = () => {
    this.setState({ nameDialog: false });
  };

  updateName = (event) => {
    const {
      target: { value },
    } = event;
    let nameError = false;
    if (!value) {
      nameError = true;
    }
    this.setState({
      name: value,
      nameError,
      pristine: false,
    });
  };

  uploadImage = async (image, cb) => {
    this.setState({ loading: true, saving: true });
    const imageUrl = await uploadImg(this.userId, image);
    this.setState({ loading: false, saving: false });
    return imageUrl;
  };

  render() {
    const { loading, saving, pristine, name, nameError, nameDialog } =
      this.state;
    const { classes } = this.props;

    return (
      <MainLayout
        title={
          <div className={classes.name}>
            {name}
            <IconButton onClick={this.openNameDialog}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </div>
        }
        loading={loading}
        topbar={
          <div className={classes.sidebarActions}>
            <QRCode uid={this.menuId} />
            <Button
              className={clsx(classes.sidebarAction, classes.saveBtn)}
              variant="contained"
              color="primary"
              onClick={this.saveToFirebase}
              disabled={pristine}
            >
              Save Changes
            </Button>
          </div>
        }
        fullWidth
        maxWidth={false}
      >
        {!(loading && !saving) && (
          <BuilderProvider
            initialState={this.initialBuilderState}
            onChange={this.saveToLocalStorage}
            onImageUpload={this.uploadImage}
            ref={this.builderRef}
          >
            <Builder />
          </BuilderProvider>
        )}

        <Dialog open={nameDialog}>
          <DialogContent className={classes.dialog}>
            <TextField
              error={nameError}
              fullWidth
              label="Menu"
              value={name}
              onChange={this.updateName}
              helperText={nameError && 'Invalid Name'}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={nameError} onClick={this.closeNameDialog}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Prompt
          message={(location, action) => {
            if (pristine || location.hash.includes('#block-')) {
              return true;
            }
            return 'All your changes will be lost if you leave this page.';
          }}
        />
      </MainLayout>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect((state) => ({
    auth: state.firebase.auth,
    company:
      (state.firestore.ordered &&
        state.firestore.ordered.companies &&
        state.firestore.ordered.companies[0]) ||
      {},
  }))
)(MenuPage);
