const constraints = {
  firstName: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  lastName: {
    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  username: {
    length: {
      minimum: 2,
      maximum: 20,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  emailAddress: {
    email: {
      message: '^Dirección de E-mail no valida.',
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  emailAddressConfirmation: {
    email: {
      message: '^La confirmación de la dirección de E-mail no es valida.',
    },

    equality: {
      attribute: 'emailAddress',
      message: '^La confirmación de la direccion de E-mail no coincide.',
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  password: {
    length: {
      minimum: 6,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },

  passwordConfirmation: {
    equality: 'password',

    length: {
      minimum: 6,
    },

    presence: {
      allowEmpty: false,
    },

    type: 'string',
  },
};

export default constraints;
