import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';

import BaseDialog from '../../../components/BaseDialog';

const NameDialog = (props) => {
  const { open, onOk, onCancel } = props;
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const menus = useSelector(
    ({ firestore: { ordered } }) => ordered && ordered.menus
  );
  const menuNames = menus.map((menu) => menu.name);

  function isValidName(name) {
    if (menuNames.includes(name)) {
      return 'This name is already taken.';
    }
    return true;
  }

  function handleOnChange(event) {
    const {
      target: { value },
    } = event;
    const name = value.trim();
    const validName = isValidName(name);
    setError(validName === true ? false : validName);
    setName(name);
  }

  return (
    <BaseDialog
      open={open}
      onCancel={onCancel}
      onOk={() => onOk(name)}
      okDisabled={!!error}
      title="Please pick a name for your menu"
    >
      <TextField
        error={!!error}
        fullWidth
        label="Menú"
        value={name}
        onChange={handleOnChange}
        helperText={error || ''}
      />
    </BaseDialog>
  );
};

export default NameDialog;
