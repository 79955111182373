import _firebase, { firestore, storage } from './firebase';

function withUID(uid) {
  return uid ? [uid] : [];
}

export async function setDocument(id, collection, data, options) {
  const documentReference = firestore
    .collection(collection)
    .doc(...withUID(id));

  const payload = {
    ...data,
    updatedAt: _firebase.firestore.FieldValue.serverTimestamp(),
  };

  if (!id) {
    payload.createdAt = _firebase.firestore.FieldValue.serverTimestamp();
  }

  try {
    await documentReference.set(payload, options);
  } catch (error) {
    console.error('Error updating document: ', error);
  }
  return documentReference;
}

export function saveMenu(id, builderData) {
  return setDocument(id, 'menus', builderData, { merge: true });
}

export function createEmptyMenu(name, owner) {
  return saveMenu(null, { name, owner });
}

export async function uploadImg(userId, image) {
  const imageReference = await storage
    .ref()
    .child('tmp')
    .child(userId)
    .child(image.name)
    .put(image);

  return await imageReference.ref.getDownloadURL();
}

export async function uploadCompanyLogo(userId, image) {
  const imageReference = await storage
    .ref()
    .child('tmp')
    .child(userId)
    .child('companies')
    .child(image.name)
    .put(image);

  return await imageReference.ref.getDownloadURL();
}

export async function saveCompany(id, companyData) {
  return setDocument(id, 'companies', companyData, { merge: true });
}

export async function getMenu(id) {
  const menuReference = await firestore.collection('menus').doc(id).get();
  const menu = menuReference.data();
  return menu;
}

export async function checkAndRedeemCode(id) {
  const betaCodeReference = await firestore
    .collection('betaCodes')
    .doc(id)
    .get();
  if (betaCodeReference.exists) {
    const { redeemed } = betaCodeReference.data();
    if (!redeemed) {
      await firestore
        .collection('betaCodes')
        .doc(id)
        .update({ redeemed: true });
      return true;
    }
  }
  return false;
}

export async function getCompanies(ownerId) {
  const companiesReference = await firestore
    .collection('companies')
    .where('owner', '==', ownerId)
    .get();

  return companiesReference.docs.map((company) => ({
    id: company.id,
    ...company.data(),
  }));
}
