import React, { useState } from 'react';
import {
  makeStyles,
  IconButton,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es-mx';
import {
  CropFree as CropFreeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import QRCodeDialog from '../../../components/QRCodeDialog';
import { useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      maxWidth: '50%',
      '&:hover $btnContainer': {
        visibility: 'visible',
      },
      cursor: 'pointer',
    },
    subContainer: {
      '&::after': {
        content: '" "',
        display: 'block',
        paddingBottom: '100%',
      },
      maxWidth: 'calc(100% - 8px)',
      width: 300,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '15px 7.5px 0 7.5px',
      color: theme.palette.primary.main,
    },
    subContainerActive: {
      borderColor: theme.palette.secondary.main,
    },
    btn: {
      borderRadius: 100,
      height: 40,
      width: 40,
      minWidth: 0,
      margin: '0 5px',
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
      },
    },
    btnContainer: {
      visibility: 'hidden',
    },
    menuItem: {
      marginTop: 20,
    },
    menuDate: {
      fontSize: 12,
    },
  };
});

export function MenuContainer(props) {
  const classes = useStyles();
  const { children, active, ...rest } = props;
  return (
    <div {...rest} className={classes.container}>
      <div
        className={clsx(
          classes.subContainer,
          active && classes.subContainerActive
        )}
      >
        <Box textAlign="center">{children}</Box>
      </div>
    </div>
  );
}

moment.locale('es-mx');

function MiniatureMenu(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const { menu = {}, uid } = props;
  const [QR, setQR] = useState(false);

  function showQR() {
    setQR(true);
  }

  function hideQR() {
    setQR(false);
  }

  async function confirmDelete() {
    var x = window.confirm('Are you sure you want to delete this menu?');
    if (x) {
      await firestore.doc(`menus/${uid}`).delete();
    }
  }

  return (
    <MenuContainer active>
      <Grid className={classes.menuItem}>
        <Box className={classes.menuOption}>
          <div className={classes.menuName}>
            <Typography variant="h5" color="secondary">
              {menu.name}
            </Typography>
          </div>
          <div className={classes.menuDate}>
            Created: {moment(menu.createdAt.toDate()).calendar()}
          </div>
          <div className={classes.menuDate}>
            Modified: {moment(menu.updatedAt.toDate()).calendar()}
          </div>
        </Box>
      </Grid>
      <div className={classes.btnContainer}>
        <IconButton
          onClick={showQR}
          className={classes.btn}
          variant="contained"
          color="primary"
        >
          <CropFreeIcon />
        </IconButton>
        <IconButton
          component={Link}
          to={`/dashboard/menus/${uid}`}
          className={classes.btn}
          variant="contained"
          color="primary"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={confirmDelete}
          className={classes.btn}
          variant="contained"
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <QRCodeDialog uid={uid} open={QR} onClose={hideQR} />
    </MenuContainer>
  );
}

MiniatureMenu.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.object,
    updatedAt: PropTypes.object,
  }).isRequired,
  uid: PropTypes.string.isRequired,
};

export default React.memo(MiniatureMenu);
