import React from 'react';
import { Redirect } from 'react-router-dom';
// Main Routes
import SignInPage from '../pages/SignInPage';
import SignUpPage from '../pages/SignUpPage';
import SignOutPage from '../pages/SignOutPage';
import PublicMenuPage from '../pages/PublicMenuPage';
import DashboardRouter from './DashboardRouter';
// Dashboard Routes
import DashboardPage from '../pages/DashboardPage';
import CreateMenuPage from '../pages/CreateMenuPage';
import SettingsPage from '../pages/SettingsPage';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/dashboard',
    component: DashboardRouter,
    private: true,
  },
  {
    path: '/login',
    exact: true,
    component: SignInPage,
  },
  {
    path: '/register',
    exact: true,
    component: SignUpPage,
  },
  {
    path: '/signout',
    exact: true,
    component: SignOutPage,
  },
  {
    path: '/menus/:menuId',
    exact: true,
    component: PublicMenuPage,
  },
];

export const dashboard = [
  {
    path: '/dashboard',
    exact: true,
    component: DashboardPage,
  },
  {
    path: '/dashboard/settings',
    exact: true,
    component: SettingsPage,
  },
  {
    path: '/dashboard/menus/create',
    exact: true,
    component: CreateMenuPage,
  },
  {
    path: '/dashboard/menus/:menuId',
    exact: true,
    component: CreateMenuPage,
  },
];

export default routes;
