import React from 'react';
import { makeStyles } from '@material-ui/core';

import Canvas from '../Canvas';
import Sidebar from '../Sidebar';
import ElementsDialog from '../ElementsDialog';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      width: '100%',
      height: '100%',
      border: '1px solid #e8eaed',
    },
    sidebarContainer: {
      borderRight: '1px solid #e8eaed',
      backgroundColor: '#fafafa',
      minWidth: 300,
      width: '30%',
      maxWidth: 400,
    },
    canvasContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
  };
});

function Builder() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.sidebarContainer}>
        <Sidebar />
      </div>
      <div className={classes.canvasContainer}>
        <Canvas />
      </div>
      <ElementsDialog />
    </div>
  );
}

Builder.propTypes = {};
export default Builder;
