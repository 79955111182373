import React from 'react';
import { map } from 'lodash';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  makeStyles,
} from '@material-ui/core';
import FontPicker from 'font-picker-react';

import FieldImage from './FieldImage';
import FieldColorpicker from './FieldColorpicker';

const useStyles = makeStyles({
  fontPicker: {
    '& #font-picker': {
      marginTop: 10,
      '& .dropdown-button': {
        background: 'none',
      },
    },
  },
});

const Field = (props) => {
  const classes = useStyles();
  const { name, value, onChange, type, label, options } = props;
  const sharedProps = {
    name,
    value,
    onChange,
  };

  switch (type) {
    case 'text': {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <Input {...sharedProps} fullWidth />
        </FormControl>
      );
    }
    case 'image': {
      return <FieldImage {...sharedProps} />;
    }
    case 'textarea': {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <Input {...sharedProps} multiline fullWidth />
        </FormControl>
      );
    }
    case 'colorpicker': {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <FieldColorpicker {...sharedProps} />
        </FormControl>
      );
    }
    case 'fontpicker': {
      return (
        <FormControl className={classes.fontPicker} component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <FontPicker
            apiKey={process.env.REACT_APP_GOOGLE_FONTS_API_KEY}
            activeFontFamily={value}
            onChange={(nextFont) => {
              onChange({ name, value: nextFont.family });
            }}
          />
        </FormControl>
      );
    }
    case 'radio': {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup {...sharedProps}>
            {map(options, (option, idx) => (
              <FormControlLabel
                key={idx}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    }
    default: {
      return 'Undefined';
      // throw new Error('Not found input of type:' + type);
    }
  }
};

export default Field;
