import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { initializeBlock } from './helpers';
import { isNumber } from 'lodash';

export const BuilderContext = createContext();
export const EditingContext = createContext();

let insertAfterIndex = null;

const SETTING_FIELDS = [
  {
    name: 'background',
    label: 'Background Color',
    type: 'colorpicker',
  },
  {
    name: 'fontColor',
    label: 'Font Color',
    type: 'colorpicker',
  },
  {
    name: 'menuBackground',
    label: 'AppBar Color',
    type: 'colorpicker',
  },
  {
    name: 'menuColor',
    label: 'AppBar Icon Color',
    type: 'colorpicker',
  },
  {
    name: 'fontFamily',
    label: 'Font Family',
    type: 'fontpicker',
  },
];

class BuilderProvider extends Component {
  constructor(props) {
    super();
    this.state = {
      blocks: [],
      edit: false,
      editId: null,
      block: false,
      nextId: 0,
      elementsDialog: false,
      settings: {},
      ...props.initialState,
    };
  }

  toggleElementsDialog = () => {
    this.setState({ elementsDialog: !this.state.elementsDialog });
  };

  editBlock = (block) => {
    if (this.state.editId === block.id) return;
    this.saveAndQuitEditing(() => {
      this.setBuilderState({
        edit: true,
        editId: block.id,
        block,
      });
      // Make sure index is clean
      insertAfterIndex = null;
    });
  };

  insertBlock = (index) => {
    this.saveAndQuitEditing(() => {
      insertAfterIndex = index + 1;
      this.toggleElementsDialog();
    });
  };

  addBlock = (blockId) => {
    const { nextId } = this.state;
    const block = initializeBlock('block-' + nextId, blockId);
    let blocks = {
      $push: [block],
    };
    if (isNumber(insertAfterIndex)) {
      blocks = {
        $splice: [[insertAfterIndex, 0, block]],
      };
    }
    this.setBuilderState({
      blocks: update(this.state.blocks, blocks),
      edit: true,
      editId: block.id,
      block,
      nextId: nextId + 1,
      elementsDialog: false,
    });
    // Make sure index is clean
    insertAfterIndex = null;
  };

  updateBlocks = (blocks) => {
    this.setBuilderState({
      blocks,
    });
  };

  setBuilderState = (state) => {
    const _this = this;
    this.setState(state, () => {
      const { onChange } = _this.props;
      const { blocks, nextId, settings } = _this.state;

      onChange({
        blocks,
        nextId,
        settings,
      });
    });
  };

  updateBlockProp = (name, value) => {
    const { block, settings } = this.state;
    // update Settings
    if (!block) {
      this.setBuilderState({
        settings: update(settings, {
          [name]: {
            $set: value,
          },
        }),
      });
    } else {
      this.setBuilderState({
        block: update(block, {
          data: {
            [name]: {
              $set: value,
            },
          },
        }),
      });
    }
  };

  imageControlChange = async ({ name, value }) => {
    const { onImageUpload } = this.props;
    const url = await onImageUpload(value);
    this.updateBlockProp(name, url);
  };

  colorpickerControlChange = ({ name, value }) => {
    this.updateBlockProp(name, value);
  };

  fontpickerControlChange = ({ name, value }) => {
    this.updateBlockProp(name, value);
  };

  /**
   * Handles form field control change
   * @param {String} blockId The type of form field
   * @param {Object} event Event or argument
   */
  onControlChange = (blockId, event) => {
    if (blockId === 'image') return this.imageControlChange(event);
    if (blockId === 'colorpicker') return this.colorpickerControlChange(event);
    if (blockId === 'fontpicker') return this.fontpickerControlChange(event);
    const {
      target: { name, value },
    } = event;

    this.updateBlockProp(name, value);
  };

  onCancel = () => {
    this.saveAndQuitEditing(() => {}, 'setBuilderState');
  };

  saveAndQuitEditing = (cb = () => {}, updateState = 'setState') => {
    if (!this.state.block) return cb();

    const { blocks, block } = this.state;
    const blockIndex = blocks.findIndex((b) => b.id === block.id);
    this[updateState](
      {
        blocks: update(blocks, {
          $splice: [[blockIndex, 1, block]],
        }),
        edit: false,
        editId: null,
        block: null,
      },
      () => {
        const { blocks, nextId, settings } = this.state;
        cb({
          blocks,
          nextId,
          settings,
        });
      }
    );
  };

  deleteBlock = (id) => {
    const { blocks, editId } = this.state;
    const update = blocks.filter((block) => block.id !== id);
    if (editId === id) {
      this.setBuilderState({
        blocks: update,
        edit: false,
        editId: null,
        block: null,
      });
    } else {
      this.updateBlocks(update);
    }
  };

  render() {
    const { blocks, edit, editId, block, elementsDialog, settings } =
      this.state;
    const builderVal = {
      blocks,
      edit,
      editId,
      elementsDialog,
      addBlock: this.addBlock,
      updateBlocks: this.updateBlocks,
      editBlock: this.editBlock,
      insertBlock: this.insertBlock,
      deleteBlock: this.deleteBlock,
      toggleElementsDialog: this.toggleElementsDialog,
      settings,
    };

    const editingVal = {
      editId,
      block,
      onControlChange: this.onControlChange,
      onCancel: this.onCancel,
      settingFields: SETTING_FIELDS,
      settings,
    };

    return (
      <BuilderContext.Provider value={builderVal}>
        <EditingContext.Provider value={editingVal}>
          {this.props.children}
        </EditingContext.Provider>
      </BuilderContext.Provider>
    );
  }
}

BuilderProvider.propTypes = {
  initialState: PropTypes.shape({
    blocks: PropTypes.array,
    nextId: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
};

export default BuilderProvider;
