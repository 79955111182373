import { Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';

import Logo from '../../illustrations/logo-mobile.svg';
import Register from '../../illustrations/register.png';

const useStyle = makeStyles((theme) => ({
  container: {
    background: theme.palette.primary.main,
    height: '100vh',
  },
  image: {
    background: `url("${Register}") 70% center/cover`,
    height: '100%',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  form: {
    padding: '30px 60px',
  },
  logo: {
    marginBottom: 30,
  },
  button: {
    color: '#ffffea',
    borderRadius: 40,
    textTransform: 'none',
    boxShadow: '4px 4px 0px 0px rgba(0,0,0,0.14)',
  },
}));

function AuthLayout(props) {
  const classes = useStyle();
  const auth = useSelector((state) => state.firebase.auth);
  const { children } = props;
  const {
    push,
    location: { pathname },
  } = useHistory();

  useState(() => {
    if (auth && auth.uid) {
      push('/');
    }
  }, [auth]);

  const isLogin = pathname === '/login';
  return (
    <Grid container className={classes.container}>
      <Grid className={classes.form}>
        <Grid justifyContent="space-between" container className={classes.logo}>
          <a rel="nofollow noopener noreferrer" href="https://minucs.com">
            <img src={Logo} alt="Minucs Logo" />
          </a>

          <Button
            onClick={() => push(isLogin ? '/register' : '/login')}
            variant="contained"
            color="secondary"
            classes={{
              root: classes.button,
            }}
          >
            {isLogin ? 'Register' : 'Sign In'}
          </Button>
        </Grid>

        {children}
      </Grid>
      <Grid className={classes.image}></Grid>
    </Grid>
  );
}

export default AuthLayout;
