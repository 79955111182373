import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { map, size } from 'lodash';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { isLoaded } from 'react-redux-firebase';
import { Add as AddIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import MainLayout from '../../layouts/MainLayout';
import Loader from '../../components/Loader';
import MiniatureMenu, { MenuContainer } from './MiniatureMenu';
import NameDialog from './NameDialog';
import { createEmptyMenu } from '../../db';
import BaseDialog from '../../components/BaseDialog';

const useStyles = makeStyles((theme) => {
  return {
    h100: {
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
    },
    container: {
      maxHeight: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
        cursor: 'pointer',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '30px',
        backgroundColor: theme.palette.secondary.main,
      },
    },
    emptyMenuIcon: {
      width: 50,
      height: 50,
      color: '#fff',
      borderRadius: 100,
      margin: '0 auto',
      marginBottom: 10,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

function Dashboard() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openMaxLimitDialog, setMaxLimitDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const savedMenus = useSelector(
    ({ firestore: { data } }) => data && data.menus
  );
  const userUID = useSelector(({ firebase: { auth } }) => auth && auth.uid);

  function toggleNameDialog() {
    setOpenDialog(!openDialog);
  }

  function maybeToggleNameDialog() {
    if (size(savedMenus) > 1) {
      setMaxLimitDialog(true);
    } else {
      toggleNameDialog();
    }
  }

  async function createMenu(menuName) {
    setSaving(true);
    try {
      const { id } = await createEmptyMenu(menuName, userUID);
      history.push(`/dashboard/menus/${id}`);
    } catch (e) {
      setSaving(false);
      console.error(e);
    }
  }

  // Loading State
  if (!isLoaded(savedMenus) || saving) {
    return (
      <MainLayout>
        <Loader show="true" color="secondary" />
      </MainLayout>
    );
  }

  // Render created menus
  return (
    <MainLayout>
      <Grid container className={classes.h100}>
        <Grid item className={classes.h100} md={4}>
          <Typography
            style={{ fontWeight: 800 }}
            color="textPrimary"
            variant="h3"
          >
            Your menus
          </Typography>
          <Typography>
            {/* Aquí aparecen todos los menús que has creado. Haz click en alguno y
            elije la opción de ‘editar’ o ‘descargar QR’. */}
            All your menus will appear here. Click on them for edit or to
            download the QR code.
          </Typography>
        </Grid>
        <Grid item md={8} className={classes.h100}>
          <Grid container justifyContent="center" className={classes.container}>
            <MenuContainer onClick={maybeToggleNameDialog}>
              <Box className={classes.emptyMenuIcon}>
                <AddIcon fontSize="large" />
              </Box>
              Add Menu
            </MenuContainer>
            {savedMenus &&
              map(savedMenus, (menu, uid) =>
                menu ? <MiniatureMenu uid={uid} key={uid} menu={menu} /> : ''
              )}
          </Grid>
        </Grid>
      </Grid>
      <NameDialog
        open={openDialog}
        onOk={createMenu}
        onCancel={toggleNameDialog}
      />
      <BaseDialog
        open={openMaxLimitDialog}
        onOk={() => setMaxLimitDialog(false)}
        title="Maximun menu limit reached"
      >
        {/* <div>El número máximo de menús en la versión beta es de 2.</div> */}
        <div>Maximun number of menus on beta is 2.</div>
      </BaseDialog>
    </MainLayout>
  );
}
export default Dashboard;
