import React, { useState } from 'react';
import { SubmissionError } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import { Typography, Grid } from '@material-ui/core';

import constraints from '../../constraints';
import Button from '../../components/Button';
import AuthLayout from '../../layouts/AuthLayout';
import SignInForm from './SignInForm';

function SignInPage(props) {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const auth = firebase.auth();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  async function signInHandler(func) {
    try {
      await func();
      history.push('/');
    } catch (e) {
      // Global error handler
      dispatch({
        type: 'GLOBAL_ERROR',
        error: e.message,
      });
      setLoading(false);
      const opts = { variant: 'error' };
      switch (e.code) {
        case 'auth/argument-error':
        case 'auth/missing-android-pkg-name':
        case 'auth/missing-continue-uri':
        case 'auth/missing-ios-bundle-id':
        case 'auth/invalid-continue-uri':
        case 'auth/unauthorized-continue-uri':
          enqueueSnackbar('Woops... Something went wrong.', opts);
          return;

        default:
          enqueueSnackbar('Invalid credentials', opts);
          return;
      }
    }
  }

  async function login(email, password) {
    signInHandler(() => firebase.login({ email, password }));
  }

  async function temporalLogin() {
    signInHandler(() => auth.signInAnonymously());
  }

  function handleSubmit(values) {
    setLoading(true);
    return new Promise((resolve) => {
      const { emailAddress, password } = values;
      const errors = validate(
        {
          emailAddress: emailAddress,
          password: password,
        },
        {
          emailAddress: constraints.emailAddress,
          password: constraints.password,
        }
      );
      if (errors) {
        setLoading(false);
        throw new SubmissionError(errors);
      } else {
        login(emailAddress, password);
        resolve();
      }
    });
  }

  return (
    <AuthLayout>
      <div style={{ maxWidth: 400 }}>
        <Typography
          style={{ fontWeight: 800 }}
          color="textPrimary"
          variant="h3"
          align="center"
        >
          Sign In
        </Typography>
        <SignInForm loading={loading} onSubmit={handleSubmit} />
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <Typography
          style={{ fontWeight: 400 }}
          color="textPrimary"
          variant="h5"
          align="center"
        >
          Or use a temporal account and see how Minucs works.
        </Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            onClick={temporalLogin}
            variant="contained"
            // type="submit"
            // disabled={pristine || submitting}
            color="secondary"
          >
            Temporal Account
          </Button>
        </Grid>
      </div>
    </AuthLayout>
  );
}
export default SignInPage;
