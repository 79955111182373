import React, { useCallback, useContext } from 'react';
import { useDrop } from 'react-dnd';
import { IconButton, makeStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import update from 'immutability-helper';

import { BuilderContext } from '../../BuilderProvider';
import Block from '../Block';
import CanvasBlock from '../CanvasBlock';

const useStyles = makeStyles((theme) => {
  return {
    canvasContent: {
      position: 'relative',
      height: '100%',
      overflow: 'hidden auto',
      padding: '0 !important',
    },
    insertBlock: {
      width: '100%',
      height: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    navigation: {
      padding: 0,
    },
  };
});

const CanvasElements = React.memo((props) => {
  let navigationElements = [];
  const classes = useStyles();
  const [, drop] = useDrop({ accept: 'BLOCK' });
  const {
    blocks,
    updateBlocks,
    edit,
    editId,
    editBlock,
    insertBlock,
    deleteBlock,
    toggleElementsDialog,
    settings: { company },
  } = props;
  const onMove = useCallback(
    (dragIndex, hoverIndex) => {
      const dragBlock = blocks[dragIndex];
      updateBlocks(
        update(blocks, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragBlock],
          ],
        })
      );
    },
    [blocks, updateBlocks]
  );

  const onEdit = (id) => {
    editBlock(blocks.find((block) => block.id === id));
  };

  const onAdd = (id) => {
    insertBlock(blocks.findIndex((block) => block.id === id));
  };

  const renderBlock = (block, index) => {
    if (block.blockId === 'title') {
      navigationElements.push({
        id: block.id,
        title: block.data.title,
      });
    }

    return (
      <CanvasBlock
        isBeingEdited={edit && editId === block.id}
        key={block.id}
        id={block.id}
        index={index}
        onMove={onMove}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={deleteBlock}
        data={block.data}
        blockId={block.blockId}
      />
    );
  };

  const renderedBlocks = blocks.map((block, idx) => renderBlock(block, idx));

  return (
    <div id="drawer-container" ref={drop} className={classes.canvasContent}>
      {navigationElements && navigationElements.length > 0 && (
        <Block
          id={'navigation'}
          data={{
            elements: navigationElements,
            ...props.navigation,
            company,
          }}
          blockId={'navigation'}
          rootClass={classes.navigation}
          naked
        />
      )}
      {renderedBlocks}
      <div className={classes.insertBlock}>
        {!blocks.length && (
          <div>
            Aun no tienes elementos <br />
            Agrega tu primer elemento
          </div>
        )}
        <IconButton
          onClick={toggleElementsDialog}
          variant="contained"
          color="primary"
        >
          <AddIcon fontSize="large" />
        </IconButton>
      </div>
    </div>
  );
});

export default (props) => {
  const { navigation } = props;
  const {
    blocks,
    updateBlocks,
    edit,
    editId,
    editBlock,
    insertBlock,
    deleteBlock,
    toggleElementsDialog,
    settings,
  } = useContext(BuilderContext);
  const updatedProps = {
    navigation,
    blocks,
    updateBlocks,
    edit,
    editId,
    editBlock,
    insertBlock,
    deleteBlock,
    toggleElementsDialog,
    settings,
  };
  return <CanvasElements {...updatedProps} />;
};
