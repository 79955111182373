const registeredBlocks = new Map();

export function registerBlock(render, config) {
  registeredBlocks.set(config.id, {
    ...config,
    render,
  });
}

export function getRegisteredBlock(id) {
  return registeredBlocks.get(id);
}

export function getRegisteredBlocks() {
  return Array.from(registeredBlocks.values()).map((block) => {
    const { id, icon, name, hidden } = block;
    return {
      id,
      icon,
      name,
      hidden,
    };
  });
}

export function getBlockRenderMethod(id) {
  return getRegisteredBlock(id).render;
}

export function getBlockControls(id) {
  return getRegisteredBlock(id).controls;
}

export function initializeBlock(id, blockId) {
  const { data = {} } = getRegisteredBlock(blockId);
  return {
    id,
    blockId,
    data: Object.assign({}, data),
  };
}

export function toCSSUnit(input) {
  if (
    typeof input === 'string' &&
    (input.includes('px') || input.includes('%'))
  ) {
    return input;
  }
  if (typeof input === 'number' && !isNaN(input)) {
    return input;
  }

  return parseInt(input);
}

export default {};
