import React, { memo } from 'react';

import ImageIcon from '@material-ui/icons/Image';
import { toCSSUnit, registerBlock } from '../../helpers';
import defaultImage from './image.png';

const directions = {
  top: 'flex-start',
  left: 'flex-start',
  bottom: 'flex-end',
  right: 'flex-end',
  center: 'center',
};

function BlockImage(props) {
  const { width, height, alignX, url } = props;

  const { imageUrl } = typeof url === 'object' ? url : { imageUrl: url };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: directions[alignX],
      }}
    >
      <img
        style={{ width: toCSSUnit(width), height: toCSSUnit(height) }}
        src={imageUrl || defaultImage}
        alt=""
      />
    </div>
  );
}

const config = {
  id: 'image',
  name: 'Image',
  icon: <ImageIcon />,
  data: {
    width: 200,
    height: 100,
    alignX: 'center',
    url: '',
  },
  controls: [
    {
      id: 'content',
      name: 'Content',
      fields: [
        {
          name: 'url',
          label: 'Image',
          type: 'image',
        },
      ],
    },
    {
      id: 'style',
      name: 'Style',
      fields: [
        {
          name: 'width',
          label: 'Width',
          type: 'text',
        },
        {
          name: 'height',
          label: 'Height',
          type: 'text',
        },
        {
          name: 'alignX',
          label: 'Alignment',
          type: 'radio',
          options: {
            center: {
              value: 'center',
              label: 'Center',
            },
            left: {
              value: 'left',
              label: 'Left',
            },
            right: {
              value: 'right',
              label: 'Right',
            },
          },
        },
      ],
    },
  ],
};

registerBlock(memo(BlockImage), config);

export default BlockImage;
