import React, { memo } from 'react';

import BlockSeparatorIcon from './block-separator.svg';
import { registerBlock, toCSSUnit } from '../../helpers';

function BlockSeparator(props) {
  const { padding, color, borderWidth, borderStyle } = props;
  return (
    <div
      style={{
        paddingTop: toCSSUnit(padding),
        paddingBottom: toCSSUnit(padding),
      }}
    >
      <hr style={{ borderWidth, borderColor: color, borderStyle }} />
    </div>
  );
}

const config = {
  id: 'separator',
  name: 'Separator',
  icon: <img src={BlockSeparatorIcon} alt="Block Separator Icon" />,
  data: {
    padding: 30,
    borderStyle: 'dashed',
    color: '#ccc',
    borderWidth: 1,
    backgroundColor: '#fff',
  },
  controls: [
    {
      id: 'style',
      name: 'Style',
      fields: [
        {
          name: 'borderStyle',
          label: 'Style',
          type: 'radio',
          options: {
            solid: {
              value: 'solid',
              label: 'Solid',
            },
            dashed: {
              value: 'dashed',
              label: 'Dashed',
            },
          },
        },
        {
          name: 'padding',
          label: 'Spacing(Padding)',
          type: 'text',
        },
        {
          name: 'color',
          label: 'Color',
          type: 'colorpicker',
        },
      ],
    },
  ],
};

registerBlock(memo(BlockSeparator), config);

export default BlockSeparator;
