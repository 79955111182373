import React from 'react';
import { makeStyles } from '@material-ui/core';
import BlockControls from '../BlockControls';
import CanvasSettings from '../CanvasSettings';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  };
});

function Sidebar() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BlockControls />
      <CanvasSettings />
    </div>
  );
}

export default Sidebar;
