import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';

import { BuilderContext } from '../../BuilderProvider';
import AvailableBlocks from '../AvailableBlocks';

const MenuControls = React.memo((props) => {
  const { elementsDialog, addBlock, toggleElementsDialog } = props;

  return (
    <Dialog open={elementsDialog} keepMounted maxWidth="xl">
      <DialogTitle>Inserta un nuevo elemento</DialogTitle>
      <DialogContent>
        <AvailableBlocks addBlock={addBlock} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={toggleElementsDialog}
          autoFocus
          variant="contained"
          color="secondary"
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
});
export default () => {
  const { elementsDialog, addBlock, toggleElementsDialog } = useContext(
    BuilderContext
  );
  const props = {
    elementsDialog,
    addBlock,
    toggleElementsDialog,
  };
  return <MenuControls {...props} />;
};
