import camelCase from 'camelcase';

import { createTheme } from '@material-ui/core/styles';

import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey as gray,
  blueGrey as blueGray,
} from '@material-ui/core/colors';

const colors = {
  red: {
    id: 'red',
    name: 'Red',
    import: red,
  },

  pink: {
    id: 'pink',
    name: 'Pink',
    import: pink,
  },

  purple: {
    id: 'purple',
    name: 'Purple',
    import: purple,
  },

  deepPurple: {
    id: 'deep-purple',
    name: 'Deep Purple',
    import: deepPurple,
  },

  indigo: {
    id: 'indigo',
    name: 'Indigo',
    import: indigo,
  },

  blue: {
    id: 'blue',
    name: 'Blue',
    import: blue,
  },

  lightBlue: {
    id: 'light-blue',
    name: 'Light Blue',
    import: lightBlue,
  },

  cyan: {
    id: 'cyan',
    name: 'Cyan',
    import: cyan,
  },

  teal: {
    id: 'teal',
    name: 'Teal',
    import: teal,
  },

  green: {
    id: 'green',
    name: 'Green',
    import: green,
  },

  lightGreen: {
    id: 'light-green',
    name: 'Light Green',
    import: lightGreen,
  },

  lime: {
    id: 'lime',
    name: 'Lime',
    import: lime,
  },

  yellow: {
    id: 'yellow',
    name: 'Yellow',
    import: yellow,
  },

  amber: {
    id: 'amber',
    name: 'Amber',
    import: amber,
  },

  orange: {
    id: 'orange',
    name: 'Orange',
    import: orange,
  },

  deepOrange: {
    id: 'deep-orange',
    name: 'Deep Orange',
    import: deepOrange,
  },

  brown: {
    id: 'brown',
    name: 'Brown',
    import: brown,
  },

  gray: {
    id: 'gray',
    name: 'Gray',
    import: gray,
  },

  blueGray: {
    id: 'blue-gray',
    name: 'Blue Gray',
    import: blueGray,
  },
};

const getColor = (colorId) => {
  if (!colorId) {
    return null;
  }

  colorId = camelCase(colorId);

  return colors[colorId];
};

const defaultPrimaryColor = getColor('blue-gray');
const defaultSecondaryColor = getColor('amber');
const defaultDark = 'light';

const defaultTheme = createTheme({
  palette: {
    text: {
      primary: '#031C40',
    },
    primary: {
      light: '#FFFFEA',
      main: '#FF7733',
      dark: '#FF9326',
    },
    secondary: {
      main: '#031C40',
    },
    type: defaultDark,
  },

  primaryColor: defaultPrimaryColor,
  secondaryColor: defaultSecondaryColor,
  dark: defaultDark,

  typography: {
    fontFamily: [
      'Catamaran',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const appearance = {};

appearance.colors = colors;

appearance.defaultPrimaryColor = defaultPrimaryColor;
appearance.defaultSecondaryColor = defaultSecondaryColor;
appearance.defaultDark = defaultDark;

appearance.defaultTheme = defaultTheme;

export default appearance;
