import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Fab, Tabs, Box, Tab, Tooltip } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { EditingContext } from '../../BuilderProvider';
import Field from '../Field';
import { getBlockControls } from '../../helpers';

const useStyles = makeStyles((theme) => {
  return {
    sidebarControls: {
      flex: 1,
    },
    closeBtnContainer: {
      position: 'relative',
    },
    closeBtn: {
      cursor: 'pointer',
      position: 'absolute',
      top: -theme.spacing(2),
      right: -theme.spacing(2),
      zIndex: 99999,
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),

      minHeight: 'initial',
    },
    optionField: {
      marginBottom: theme.spacing(2),
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const BlockControls = React.memo((props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { block, onControlChange, onCancel, editId } = props;
  const { data, blockId } = block || {};
  const controls = blockId ? getBlockControls(blockId) : [];

  useEffect(() => {
    if (value !== 0) {
      setValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!block) {
    return '';
  }

  const renderOptionField = (field) => {
    const value = data[field.name] || '';
    return (
      <div key={field.name} className={classes.optionField}>
        <Field
          {...field}
          value={value}
          onChange={(event) => onControlChange(field.type, event)}
        />
      </div>
    );
  };

  const [tabs, tabPanels] = controls.reduce(
    (prev, control, idx) => {
      const { name, fields } = control;
      prev[0].push(<Tab key={`tab_${idx}`} value={idx} label={name} wrapped />);
      prev[1].push(
        <TabPanel key={`panel_${idx}`} value={value} index={idx}>
          {fields.map(renderOptionField)}
        </TabPanel>
      );
      return prev;
    },
    [[], []]
  );

  return (
    <>
      <div className={classes.sidebarControls}>
        <div className={clsx(classes.closeBtnContainer)}>
          <Tooltip title="Cerrar edición del bloque">
            <Fab
              className={classes.closeBtn}
              classes={{ sizeSmall: classes.small }}
              color="secondary"
              size="small"
              onClick={onCancel}
            >
              <CloseIcon fontSize="small" />
            </Fab>
          </Tooltip>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          {tabs}
        </Tabs>
        {tabPanels}
      </div>
    </>
  );
});

export default () => {
  const { block, onControlChange, onCancel, editId } = useContext(
    EditingContext
  );
  const props = {
    block,
    onControlChange,
    onCancel,
    editId,
  };
  return <BlockControls {...props} />;
};
