import React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';
import appearance from './appearance';
import store from './store';
import firebase from './firebase';
import Router from './router';

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
};

const theme = appearance.defaultTheme;

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <CookiesProvider>
              <Router />
            </CookiesProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

export default App;
