import React from 'react';
import {
  makeStyles,
  Button,
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import CenteredLayout from '../../layouts/CenteredLayout';
import MainLayout from '../../layouts/MainLayout';

const useStyles = makeStyles((theme) => {
  return {
    form: {
      width: '450px',
      border: '1px solid',
      borderRadius: '3px',
      padding: '3em',
      background: '#fff',
      borderColor: theme.primaryColor.import[400],
      position: 'relative',
    },
    divider: {
      margin: '2rem 0',
    },
    sendEmailVerification: {
      marginTop: '10px',
      fontSize: '0.6rem',
    },
  };
});

function VerifyEmailPage() {
  const classes = useStyles();
  const firebase = useFirebase();

  async function sendEmailVerification() {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
  }
  return (
    <MainLayout>
      <CenteredLayout>
        <Box textAlign="center" className={classes.form}>
          <Typography>Please verify your email address first.</Typography>
          <Divider className={classes.divider} />
          <Typography variant="subtitle1">
            {/* No has recibido el correo de confirmación? */}
            Haven't recieved the email confirmation?
          </Typography>
          <Button
            size="small"
            className={classes.sendEmailVerification}
            variant="contained"
            onClick={sendEmailVerification}
          >
            {/* Volver a enviar correo de confirmación */}
            Send email adress confirmation again
          </Button>
        </Box>
      </CenteredLayout>
    </MainLayout>
  );
}

export default VerifyEmailPage;
