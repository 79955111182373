import React from 'react';

import { Grid } from '@material-ui/core';

function CenteredLayout(props) {
  const { backgroundImage = '' } = props;
  return (
    <>
      {/* Increase the network loading priority of the background image. */}
      {backgroundImage && (
        <img
          style={{ display: 'none' }}
          src={backgroundImage}
          alt="increase priority"
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: '100%',
          backgroundImage: `url('${backgroundImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {props.children}
      </Grid>
    </>
  );
}

export default CenteredLayout;
