import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Avatar,
  ListItemAvatar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Person as PersonIcon } from '@material-ui/icons';
import Logo from '../../illustrations/logo-mobile.svg';

const styles = () => ({
  appBar: {
    padding: '0 !important',
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Events
    const { onSignOutClick, title, topbar, onSettingsClick, classes } =
      this.props;

    const { menu } = this.state;

    const menuItems = [
      {
        name: 'Settings',
        onClick: onSettingsClick,
      },
      {
        name: 'Sign out',
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    return (
      <AppBar classes={{ root: classes.appBar }} position="fixed">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Link to="/">
              <img src={Logo} alt="Minucs Logo" />
            </Link>
            <Typography
              color="inherit"
              variant="h6"
              style={{ textDecoration: 'none' }}
            >
              {title}
            </Typography>
          </Box>
          {topbar}
          <ListItemAvatar>
            <IconButton color="inherit" onClick={this.openMenu}>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </IconButton>
          </ListItemAvatar>

          <Menu
            anchorEl={menu.anchorEl}
            open={Boolean(menu.anchorEl)}
            onClose={this.closeMenu}
          >
            {menuItems.map((menuItem, index) => {
              if (menuItem.hasOwnProperty('condition') && !menuItem.condition) {
                return null;
              }

              let component = null;

              if (menuItem.to) {
                component = (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={menuItem.to}
                    onClick={this.closeMenu}
                  >
                    {menuItem.name}
                  </MenuItem>
                );
              } else {
                component = (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      this.closeMenu();

                      menuItem.onClick();
                    }}
                  >
                    {menuItem.name}
                  </MenuItem>
                );
              }

              if (menuItem.divide) {
                return (
                  <span key={index}>
                    <Divider />

                    {component}
                  </span>
                );
              }

              return component;
            })}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.propTypes = {
  // Events
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Bar);
