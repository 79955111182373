import React from 'react';
import { reduxForm } from 'redux-form';
import AuthForm from '../../components/AuthForm';

function SignInForm(props) {
  const fields = [
    {
      name: 'emailAddress',
      label: 'Email',
      fullWidth: true,
      color: 'secondary',
    },
    {
      name: 'password',
      label: 'Password',
      fullWidth: true,
      color: 'secondary',
      type: 'password',
      required: true,
    },
  ];
  return (
    <AuthForm title="Sign In" buttonTxt="Sign In" fields={fields} {...props} />
  );
}

export default reduxForm({
  form: 'SignInForm', // a unique identifier for this form
})(SignInForm);
