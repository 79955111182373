import React from 'react';
import { Field } from 'redux-form';
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import Loader from '../Loader';
import Button from '../Button';

const useStyles = makeStyles({
  form: {},
  formField: {
    margin: 10,
  },
  inputLabel: {
    color: '#031C40',
  },
  inputUnderline: {
    '&:before': {
      borderColor: '#031C40',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderColor: '#031C40',
    },
  },
});

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

function AuthForm(props) {
  const classes = useStyles();
  const {
    handleSubmit,
    pristine,
    submitting,
    loading,
    fields,
    buttonTxt,
    title,
  } = props;
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Loader show={loading} />
      <Typography color="primary">{title}</Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {fields.map((field) => {
          return (
            <Field
              key={field.name}
              component={renderTextField}
              className={classes.formField}
              InputLabelProps={{
                classes: {
                  root: classes.inputLabel,
                },
              }}
              classes={{
                classes: {
                  underline: classes.inputUnderline,
                },
              }}
              {...field}
            />
          );
        })}
        <Button
          variant="contained"
          type="submit"
          disabled={pristine || submitting}
          color="secondary"
        >
          {buttonTxt}
        </Button>
      </Grid>
    </form>
  );
}

export default AuthForm;
