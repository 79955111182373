import React, { memo } from 'react';

import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { registerBlock } from '../../helpers';

function BlockDish(props) {
  const { price, name, description } = props;
  return (
    <div>
      <div>{name}</div>
      <div>
        <small>{description}</small>
      </div>
      <div>${price}</div>
    </div>
  );
}

const config = {
  id: 'dish',
  name: 'Dish',
  icon: <RestaurantMenuIcon />,
  data: {
    name: 'Dish price',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Un',
    price: 12.4,
  },
  controls: [
    {
      id: 'content',
      name: 'Content',
      fields: [
        {
          name: 'name',
          label: 'Name',
          type: 'text',
        },
        {
          name: 'price',
          label: 'Price',
          type: 'text',
        },
        {
          name: 'description',
          label: 'Short description',
          type: 'textarea',
        },
      ],
    },
  ],
};

registerBlock(memo(BlockDish), config);

export default BlockDish;
