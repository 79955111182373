import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { dashboard as dashboardRoutes } from './routes';
import VerifyEmailPage from '../pages/VerifyEmailPage';
import NotFoundPage from '../pages/NotFoundPage';
import MainLayout from '../layouts/MainLayout';

function DashboardRouter() {
  const { emailVerified, userProfile, isAnonymous } = useSelector((state) => ({
    ...state.firebase.auth,
    userProfile: state.firestore.data.userProfile,
  }));

  if (!emailVerified && !isAnonymous) {
    return <VerifyEmailPage />;
  }

  return (
    <Switch>
      {dashboardRoutes.map((route, idx) => {
        return <Route {...route} key={idx} />;
      })}
      <Route>
        <MainLayout>
          <NotFoundPage />
        </MainLayout>
      </Route>
    </Switch>
  );
}

export default DashboardRouter;
