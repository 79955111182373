import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  FONT_FAMILY_DEFAULT,
  FontManager,
  OPTIONS_DEFAULTS,
} from '@samuelmeuli/font-manager';

import { getMenu, getCompanies } from '../../db';
import { withStyles, Box, Paper } from '@material-ui/core';
import Loader from '../../components/Loader';
import { Block } from '../../builder';

const styles = (theme) => ({
  root: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    maxWidth: theme.breakpoints.up('sm') ? 600 : '100%',
    margin: '0 auto',
    overflow: 'hidden auto',
  },
  contentContainer: {
    overflow: 'hidden auto',
    maxHeight: '100%',
    padding: '0 !important',
  },
  content: {
    height: '100%',
  },
});
class MenuPage extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      name: '',
      blocks: [],
      displayQr: false,
      loadingStatus: 'loading',
    };
    this.navigationElements = [];
    this.fontManager = new FontManager(
      process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
      FONT_FAMILY_DEFAULT,
      OPTIONS_DEFAULTS,
      () => {}
    );
  }

  componentDidMount() {
    this.fontManager
      .init()
      .then(() => {
        this.setState({
          loadingStatus: 'finished',
        });
      })
      .catch((err) => {
        this.setState({
          loadingStatus: 'error',
        });
        console.error('Error trying to fetch the list of available fonts');
        console.error(err);
      });
    if (this.props.match.params.menuId) {
      this.loadMenu();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.state &&
      this.props.location.state.showQR &&
      !this.state.loading &&
      prevState.loading
    ) {
      this.setState({
        displayQr: true,
      });
    }
  }

  loadMenu = async () => {
    const {
      match: {
        params: { menuId },
      },
    } = this.props;
    const { name, blocks, settings, owner } = await getMenu(menuId);
    const companies = await getCompanies(owner);
    const company = (companies && companies[0]) || {};

    if (settings && settings.fontFamily) {
      this.fontManager.setActiveFont(settings.fontFamily);
    }

    this.setState({
      name,
      blocks,
      settings: {
        ...settings,
        company,
      },
      loading: false,
    });
  };

  renderBlocks = () => {
    const { blocks = [] } = this.state;
    this.navigationElements = [];
    return blocks.map((block) => {
      const { blockId, data, id } = block;
      if (blockId === 'title') {
        this.navigationElements.push({
          id,
          title: data.title,
        });
      }
      return <Block id={id} blockId={blockId} data={data} />;
    });
  };

  render() {
    const {
      loading,
      settings: {
        background,
        fontColor: color,
        fontFamily,
        menuBackground,
        menuColor,
        company,
      } = {},
    } = this.state;
    const { classes } = this.props;

    const renderedBlocks = !loading && this.renderBlocks();
    return (
      <Paper className={classes.root} style={{ background, color, fontFamily }}>
        <div id="drawer-container" className={classes.contentContainer}>
          {this.navigationElements && this.navigationElements.length > 0 && (
            <Block
              id={'navigation'}
              data={{
                elements: this.navigationElements,
                menuBackground,
                menuColor,
                background,
                color,
                company,
              }}
              blockId={'navigation'}
              rootClass={classes.navigation}
              naked
            />
          )}
          <Box className={classes.content}>{renderedBlocks}</Box>
          <Loader show={loading} />
        </div>
      </Paper>
    );
  }
}

export default compose(
  withRouter,
  connect((state) => ({
    auth: state.firebase.auth,
  })),
  withStyles(styles)
)(MenuPage);
