import React from 'react';
import { map } from 'lodash';
import { makeStyles } from '@material-ui/core';
import { getRegisteredBlocks } from '../../helpers';

const useStyles = makeStyles((theme) => {
  return {
    registeredBlocks: {
      display: 'flex',
      flexWrap: 'no-wrap',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flex: 1,
    },
    registeredBlock: {
      backgroundColor: '#EDEFF2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
      minWidth: 200,
      height: 100,
      margin: '10px',
      borderRadius: 10,
      flexDirection: 'column',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #c3d1e5',
      },
    },
    registeredBlockName: {},
    registeredBlockImg: {
      '& img': {
        width: 25,
      },
    },
  };
});

function AvailableBlocks(props) {
  const classes = useStyles();
  const { addBlock } = props;
  return (
    <div className={classes.registeredBlocks}>
      {map(
        getRegisteredBlocks().filter((block) => block.hidden !== true),
        (block, idx) => (
          <div
            key={idx}
            onClick={() => addBlock(block.id)}
            className={classes.registeredBlock}
          >
            <div className={classes.registeredBlockImg}>{block.icon}</div>
            <div className={classes.registeredBlockName}>{block.name}</div>
          </div>
        )
      )}
    </div>
  );
}

export default AvailableBlocks;
