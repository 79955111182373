import React, { useState } from 'react';
import { SubmissionError } from 'redux-form';
import { useDispatch } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSnackbar } from 'notistack';
import validate from 'validate.js';
import { Typography } from '@material-ui/core';

import { checkAndRedeemCode } from '../../db';
import AuthLayout from '../../layouts/AuthLayout';
import SignUpForm from './SignUpForm';
import constraints from '../../constraints';
import Button from '../../components/Button';

function SignUpPage(props) {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function createUser(email, password) {
    try {
      await firebase.createUser({
        email,
        password,
      });
      const user = firebase.auth().currentUser;
      await user.sendEmailVerification();
      setShowConfirmation(true);
    } catch (e) {
      // Global error handler
      dispatch({
        type: 'GLOBAL_ERROR',
        error: e.message,
      });
      setLoading(false);
      const opts = { variant: 'error' };
      switch (e.code) {
        case 'auth/email-already-in-use':
          enqueueSnackbar('Email address already in use.', opts);
          return;
        case 'auth/invalid-email':
          enqueueSnackbar('Invalid email address.', opts);
          return;

        case 'auth/weak-password':
          enqueueSnackbar('Invalid/Weak password.', opts);
          return;

        default:
          enqueueSnackbar('Woops... Something went wrong.', opts);
          return;
      }
    }
  }

  async function handleSubmit(values) {
    setLoading(true);
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
      betaCode,
    } = values;
    const errors = validate(
      {
        emailAddress,
        emailAddressConfirmation,
        password,
        passwordConfirmation,
      },
      {
        emailAddress: constraints.emailAddress,
        emailAddressConfirmation: constraints.emailAddressConfirmation,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      }
    );
    if (errors) {
      setLoading(false);
      throw new SubmissionError(errors);
    } else {
      const validCode = await checkAndRedeemCode(betaCode);
      if (validCode) {
        await createUser(emailAddress, password);
      } else {
        setLoading(false);
        throw new SubmissionError({
          betaCode: 'Invalid invitation code.',
        });
      }
    }
  }

  return (
    <AuthLayout>
      <div style={{ maxWidth: 400 }}>
        <Typography
          style={{ fontWeight: 800 }}
          color="textPrimary"
          variant="h3"
        >
          {!showConfirmation ? 'Register' : 'Thanks for registering'}
        </Typography>
        {showConfirmation && (
          <>
            <Typography
              style={{ fontSize: 18 }}
              color="textPrimary"
              variant="subtitle1"
            >
              Please confirm your email address before start using Minucs.
            </Typography>
            <Button
              component="a"
              rel="nofollow noopener noreferrer"
              href="https://minucs.com"
              variant="contained"
              color="secondary"
            >
              Back to main site.
            </Button>
          </>
        )}
        {!showConfirmation && (
          <SignUpForm loading={loading} onSubmit={handleSubmit} />
        )}
      </div>
    </AuthLayout>
  );
}
export default SignUpPage;
