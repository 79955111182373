import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

const BaseDialog = (props) => {
  const {
    open,
    onCancel,
    onOk,
    title,
    cancel,
    cancelDisabled,
    okDisabled,
    ok,
    children,
  } = props;
  return (
    <Dialog open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            disabled={cancelDisabled}
            onClick={onCancel}
            variant="contained"
            color="secondary"
          >
            {cancel || 'Close'}
          </Button>
        )}
        <Button
          disabled={okDisabled}
          onClick={onOk}
          variant="contained"
          color="primary"
        >
          {ok || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BaseDialog;
