import React, { useState, memo } from 'react';
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  makeStyles,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Phone as PhoneIcon,
  Room as RoomIcon,
} from '@material-ui/icons';

import { registerBlock } from '../../helpers';

const useStyles = makeStyles({
  menuIconContainer: {
    flex: 1,
  },
  listItemPrimary: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dialogContent: {
    '& > div': {
      display: 'flex',
    },
  },
  dialogName: {
    justifyContent: 'center',
  },
});
const BlockAppBar = React.memo((props) => {
  const classes = useStyles();
  const { background, color, elements, menuBackground, menuColor, company } =
    props;
  const [drawer, setDrawer] = useState(false);
  const [dialog, setDialog] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(!drawer);
  };

  function toggleDialog() {
    setDialog(!dialog);
  }

  const navigateTo = (id) => {
    const current = window.location.href.split('#')[0];
    window.location.href = current + '#' + id;
  };

  return (
    <>
      <AppBar
        position="sticky"
        style={{ backgroundColor: menuBackground, color: menuColor }}
      >
        <Toolbar>
          <div className={classes.menuIconContainer}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </div>
          {company && company.logo && (
            <Avatar
              variant="circular"
              className={classes.avatar}
              alt=""
              src={company.logo}
              onClick={toggleDialog}
            />
          )}
        </Toolbar>
      </AppBar>
      {company && (
        <Dialog
          fullWidth
          container={document.getElementById('drawer-container')}
          style={{ position: 'absolute' }}
          BackdropProps={{ style: { position: 'absolute' } }}
          open={dialog}
        >
          <DialogContent>
            <Box className={classes.dialogContent}>
              {company.name && (
                <Box className={classes.dialogName}>
                  <Typography variant="h5">{company.name}</Typography>
                </Box>
              )}
              {company.phone && (
                <Box>
                  <PhoneIcon />
                  <Typography variant="subtitle1">
                    Phone: {company.phone}
                  </Typography>
                </Box>
              )}
              {company.address && (
                <Box>
                  <RoomIcon />
                  <Typography variant="subtitle1">
                    Address: {company.address}
                  </Typography>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={toggleDialog}
              autoFocus
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Drawer
        ModalProps={{
          container: document.getElementById('drawer-container'),
          style: { position: 'absolute' },
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            background,
            color,
            width: '50%',
          },
        }}
        BackdropProps={{ style: { position: 'absolute' } }}
        anchor="left"
        open={drawer}
        onClose={toggleDrawer}
      >
        <div
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <List>
            {elements.map((element) => (
              <ListItem button key={element.id}>
                <ListItemText
                  onClick={() => navigateTo(element.id)}
                  primary={element.title || 'Title'}
                  classes={{
                    primary: classes.listItemPrimary,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
});

const config = {
  id: 'navigation',
  name: 'Navigation Bar',
  icon: null,
  data: {
    blocks: [],
    backgroundColor: null,
    color: null,
    menuBackground: null,
    menuColor: null,
  },
  controls: [],
  hidden: true,
  naked: true,
};

registerBlock(memo(BlockAppBar), config);

export default BlockAppBar;
