import React, { useRef, memo, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { EditingContext } from '../../BuilderProvider';
import Block from '../Block';

const CanvasBlock = React.memo((props) => {
  const ref = useRef(null);
  const {
    id,
    index,
    onMove,
    onDelete,
    onEdit,
    onAdd,
    data,
    isBeingEdited,
    blockId,
  } = props;

  const [, drop] = useDrop({
    accept: 'BLOCK',
    canDrop: () => false,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMove(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [, drag, preview] = useDrag({
    item: { type: 'BLOCK', id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div ref={ref}>
      <Block
        showControls
        isBeingEdited={isBeingEdited}
        onDelete={onDelete}
        onEdit={onEdit}
        onAdd={onAdd}
        data={data}
        drag={drag}
        id={id}
        blockId={blockId}
      />
    </div>
  );
});

export default (props) => {
  const { block } = useContext(EditingContext);
  const { data, isBeingEdited, ...rest } = props;
  return (
    <CanvasBlock
      {...rest}
      isBeingEdited={isBeingEdited}
      data={isBeingEdited ? block.data : data}
    />
  );
};
