import React from 'react';
import { makeStyles, Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import clsx from 'clsx';
import Bar from '../../components/Bar';
import Loader from '../../components/Loader';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    minHeight: '100vh',
    padding: '64px 30px 0 30px',
    backgroundColor: '#fff',
    '&.fullwidth': {
      paddingLeft: 0,
      paddingRight: 0,
      '& $content, & $container': {
        padding: 0,
      },
    },
  },
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
    padding: 24,
    paddingTop: 30,
  },
});

function MainLayout(props) {
  const classes = useStyles();
  const firebase = useFirebase();
  const history = useHistory();
  const { topbar, loading, fullWidth, maxWidth, title } = props;

  function signOut() {
    firebase.logout();
    history.push('/login');
  }
  function onSettingsClick() {
    history.push('/dashboard/settings');
  }

  return (
    <div className={clsx(classes.root, fullWidth && 'fullwidth')}>
      <Container
        className={classes.container}
        fixed={!fullWidth}
        maxWidth={maxWidth}
      >
        <Bar
          title={title}
          onSettingsClick={onSettingsClick}
          onSignOutClick={signOut}
          topbar={topbar}
        />
        <Box className={classes.content}>{props.children}</Box>
        <Loader show={loading} />
      </Container>
    </div>
  );
}

MainLayout.default = {
  title: null,
  topbar: null,
  loading: false,
  fullWidth: false,
  maxWidth: false,
};

MainLayout.propTypes = {
  topbar: PropTypes.element,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.bool,
};

export default MainLayout;
