import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';

function SignOutPage(props) {
  const firebase = useFirebase();
  firebase.logout();
  return <Redirect to="/login" />;
}
export default SignOutPage;
