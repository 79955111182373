import React, { useContext } from 'react';
import { makeStyles, Tabs, Box, Tab } from '@material-ui/core';

import { EditingContext } from '../../BuilderProvider';
import Field from '../Field';

const useStyles = makeStyles((theme) => {
  return {
    sidebarControls: {
      flex: 1,
    },
    closeBtnContainer: {
      position: 'relative',
    },
    closeBtn: {
      cursor: 'pointer',
      position: 'absolute',
      top: -theme.spacing(2),
      right: -theme.spacing(2),
      zIndex: 99999,
    },
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),

      minHeight: 'initial',
    },
    optionField: {
      marginBottom: theme.spacing(2),
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const BlockControls = React.memo((props) => {
  const classes = useStyles();
  const { editId, settingFields, settings, onControlChange } = props;

  if (editId) {
    return '';
  }

  const renderOptionField = (field) => {
    const value = settings[field.name] || field.value || '';
    return (
      <div key={field.name} className={classes.optionField}>
        <Field
          {...field}
          value={value}
          onChange={(event) => onControlChange(field.type, event)}
        />
      </div>
    );
  };

  return (
    <>
      <div className={classes.sidebarControls}>
        <Tabs value={0} aria-label="wrapped label tabs example">
          <Tab value={0} label={'Global settings'} wrapped />
        </Tabs>
        <TabPanel value={0} index={0}>
          {settingFields.map(renderOptionField)}
        </TabPanel>
      </div>
    </>
  );
});

export default () => {
  const { editId, settingFields, settings, onControlChange } =
    useContext(EditingContext);
  const props = {
    editId,
    settingFields,
    settings,
    onControlChange,
  };
  return <BlockControls {...props} />;
};
