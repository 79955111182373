import React, { useState } from 'react';
import QRCode from 'qrcode';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
} from '@material-ui/core';

function QRCodeDialog(props) {
  const { uid, open, onClose, title, content } = props;

  const [image, setImage] = useState();

  const generateQR = async (text) => {
    try {
      setImage(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };
  const url =
    process.env.NODE_ENV === 'production'
      ? `https://app.minucs.com/menus/${uid}`
      : `${window.location.origin}/menus/${uid}`;
  generateQR(url);

  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle align="center">{title}</DialogTitle>}
      <DialogContent>
        {content}
        <Box align="center">
          <img src={image} alt="Generated QR Code" />
        </Box>
        <Box align="center">
          <Button
            variant="contained"
            color="primary"
            component="a"
            download="QR.png"
            href={image}
          >
            Descargar
          </Button>
        </Box>
        <Typography align="center" variant="subtitle2">
          Access via{' '}
          <a target="_blank" href={url}>
            URL
          </a>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(QRCodeDialog);
