import React, { memo } from 'react';

import { registerBlock, toCSSUnit } from '../../helpers';
import TitleIcon from '@material-ui/icons/Title';

function BlockTitle(props) {
  const { title, fontSize } = props;
  return (
    <div>
      <div style={{ fontSize: toCSSUnit(fontSize) }}>{title}</div>
    </div>
  );
}

const config = {
  id: 'title',
  name: 'Title (Section)',
  icon: <TitleIcon />,
  data: {
    title: 'Drinks',
    fontSize: 14,
  },
  controls: [
    {
      id: 'content',
      name: 'Content',
      fields: [
        {
          name: 'title',
          label: 'Section Title',
          type: 'text',
        },
      ],
    },
    {
      id: 'style',
      name: 'Style',
      fields: [
        {
          name: 'fontSize',
          label: 'Font Size',
          type: 'text',
        },
      ],
    },
  ],
};

registerBlock(memo(BlockTitle), config);

export default BlockTitle;
