import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Box, Badge, Avatar, Button } from '@material-ui/core';

import { Photo as PhotoIcon } from '@material-ui/icons';

const styles = (theme) => ({
  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: 'initial',
  },
});

class FieldImage extends Component {
  handleImgChange = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const image = files[0];

    if (!image) {
      return;
    }

    const fileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!fileTypes.includes(image.type)) {
      return;
    }

    if (image.size > 20 * 1024 * 1024) {
      return;
    }

    this.props.onChange({ name: this.props.name, value: image });
  };

  render() {
    const { classes, name, value } = this.props;
    return (
      <Grid item xs>
        <Box textAlign="center">
          <Box mb={1.5}>
            {value && (
              <Badge
                classes={{ badge: classes.badge }}
                // badgeContent={
                // <Tooltip title="Remove">
                //   <Fab
                //     classes={{ sizeSmall: classes.small }}
                //     color="secondary"
                //     size="small"
                //     onClick={this.removeImage}
                //   >
                //     <CloseIcon fontSize="small" />
                //   </Fab>
                // </Tooltip>
                // }
              >
                <Avatar
                  variant="square"
                  className={classes.avatar}
                  alt="Avatar"
                  src={value}
                />
              </Badge>
            )}

            {!value && (
              <Avatar
                variant="square"
                className={classes.avatar}
                alt="Menu Image"
              >
                {'Imagen'}
              </Avatar>
            )}
          </Box>

          <>
            <input
              name={name}
              id="item-image-input"
              type="file"
              hidden
              accept="image/*"
              onChange={this.handleImgChange}
            />

            <label htmlFor="item-image-input">
              <Button
                color="primary"
                component="span"
                startIcon={<PhotoIcon />}
                variant="contained"
              >
                Choose Image
              </Button>
            </label>
          </>
        </Box>
      </Grid>
    );
  }
}

FieldImage.defaultProps = {
  value: '',
};

FieldImage.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string.isRequired]),
};

export default withStyles(styles)(FieldImage);
