import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import AuthForm from '../../components/AuthForm';

function CompanyForm(props) {
  const fields = [
    {
      name: 'companyName',
      label: 'Name',
      fullWidth: true,
      color: 'secondary',
    },
    {
      name: 'companyPhone',
      label: 'Phone',
      fullWidth: true,
      color: 'secondary',
    },
    {
      name: 'companyAddress',
      label: 'Address',
      fullWidth: true,
      color: 'secondary',
    },
  ];
  return <AuthForm buttonTxt="Save" fields={fields} {...props} />;
}

CompanyForm = reduxForm({
  form: 'CompanyForm', // a unique identifier for this form
})(CompanyForm);

export default connect(({ firestore: { ordered } }) => {
  const companies = ordered && ordered.companies;
  const {
    name: companyName,
    phone: companyPhone,
    address: companyAddress,
  } = companies[0] || {};
  return {
    initialValues: { companyName, companyPhone, companyAddress }, // pull initial values from account reducer
  };
})(CompanyForm);
