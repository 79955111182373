import React from 'react';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.5,
    zIndex: 10,
  },
});

function Loader(props) {
  const classes = useStyles();
  const { show, color = 'primary' } = props;
  if (!show) {
    return '';
  }
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={classes.loader}
    >
      <CircularProgress color={color} />
    </Grid>
  );
}

export default Loader;
