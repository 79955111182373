import React from 'react';
import { makeStyles, Button, ButtonGroup } from '@material-ui/core';
import clsx from 'clsx';
import {
  Add as AddIcon,
  Edit as EditIcon,
  SwapVert as SwapVertIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

import { getBlockRenderMethod } from '../../helpers';

const useStyles = makeStyles((theme) => {
  return {
    canvasBlock: {
      width: '100%',
      position: 'relative',
      padding: 10,
      '&.show-controls .controls': {
        display: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
      },
      '&.show-controls:hover': {
        borderTop: '1px solid #c3d1e5',
        borderBottom: '1px solid #c3d1e5',
        '& .controls': {
          display: 'block',
        },
      },
      '&.show-controls.editing': {
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  };
});

const BaseBlock = React.memo((props) => {
  const classes = useStyles();
  const {
    id,
    isBeingEdited,
    showControls,
    onDelete,
    onEdit,
    onAdd,
    data,
    drag,
    blockId,
    rootClass,
    naked,
  } = props;
  const BlockContent = getBlockRenderMethod(blockId);

  if (naked) {
    return <BlockContent {...data} />;
  }

  return (
    <div
      id={id}
      className={clsx(
        classes.canvasBlock,
        isBeingEdited && 'editing',
        showControls && 'show-controls',
        rootClass
      )}
    >
      {showControls && (
        <div className="controls">
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button onClick={() => onAdd(id)}>
              <AddIcon fontSize="small" />
            </Button>
            <Button onClick={() => onEdit(id)}>
              <EditIcon fontSize="small" />
            </Button>
            {/* <Button onClick={() => onCopy(id)}>
              <FileCopyIcon fontSize="small" />
            </Button> */}
            <Button ref={drag}>
              <SwapVertIcon fontSize="small" />
            </Button>
            <Button onClick={() => onDelete(id)}>
              <DeleteIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </div>
      )}
      <BlockContent {...data} />
    </div>
  );
});

export default BaseBlock;
